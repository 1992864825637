<template>
	<div class="o-wrap">
		<el-page-header @back="$router.go(-1)" content="发布新闻"/>
		<NewForm @eventForm="handleGetForm" class="purForm"/>
	</div>
</template>

<script>
	import NewForm from '@/components/News/Form'
	export default {
		methods: {
			handleGetForm(form) {
				this.$axios.post('/addnews', form).then(data => {
					this.$message.success('发布成功');
					this.$router.push('/news')
				})
			}
		},
		components: {
			NewForm
		}
	}
</script>
